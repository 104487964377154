import { render, staticRenderFns } from "./SwiperCarrousel.vue?vue&type=template&id=016fb4d8&"
import script from "./SwiperCarrousel.vue?vue&type=script&lang=js&"
export * from "./SwiperCarrousel.vue?vue&type=script&lang=js&"
import style0 from "./SwiperCarrousel.vue?vue&type=style&index=0&id=016fb4d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperCarrouselSlideBasic: require('/codebuild/output/src363143284/src/eddys/components/SwiperCarrouselSlideBasic.vue').default,SwiperCarrouselSlide: require('/codebuild/output/src363143284/src/eddys/components/SwiperCarrouselSlide.vue').default})
