import { render, staticRenderFns } from "./SingleColumnContent.vue?vue&type=template&id=2addbe92&"
import script from "./SingleColumnContent.vue?vue&type=script&lang=js&"
export * from "./SingleColumnContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubTitleRow: require('/codebuild/output/src363143284/src/eddys/components/SubTitleRow.vue').default,ResponsivePicture: require('/codebuild/output/src363143284/src/eddys/components/global/ResponsivePicture.vue').default})
